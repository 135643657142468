const acePlaySport = {
    id: "ace-play-sport",
    name: "ACE PLAY SPORT",
    city: "臺北",
    space: "商空",
    size: "21坪",
    description: {
        left: (
            <>
                羽毛球運動承載著深厚的人文脈絡與不斷進化的運動科技，而本案將看似相對的二種特質，經由空間設計融合交織並存，形塑一處兼具歷史底蘊與未來視野的場域。本案空間以 「木紋年輪紋理」與「霧面金屬光澤」 兩大元素為設計核心，透過材質的對比與融合，展現羽毛球運動的傳承與革新。溫潤的木紋象徵時間累積的底蘊，冷冽的霧面金屬則蘊涵現代的精準與快速感，讓傳統與當代在此共鳴。<br /><br />
                <strong>從騎樓到店內：傳統街屋的當代詮釋</strong><br />
                ACE PLAY SPORT 座落於台北社子傳統街屋，設計時充分利用街屋的深邃縱深，讓視覺敘事自騎樓展開。「木紋元素」自騎樓延伸入室內，懸吊於騎樓的「鍥型量體」不僅強化空間引導，更象徵羽球擊出的力量軌跡，營造出動感且充滿張力的空間體驗。<br />
            </>
        ),
        right: (
            <>
                <strong>羽毛球語彙轉譯：空間中的運動意象</strong><br />
                店內的中島設計，靈感源自「羽毛片狀層疊」，透過幾何結構與錯落層次，將羽毛球運動的動態感，轉化為具象空間裝置。同時，牆面採用「穿孔展示板」提升產品陳列的靈活性，同時呼應球拍穿線的細膩工藝。<br />

                <strong>科技感天花設計：均質光感與純粹視覺</strong><br />
                天花板採用「光膜設計」整合空調主機，維持老街屋挑高開闊的原貌，並透過均勻的光源，使空間氛圍更顯輕盈純粹，亦賦予商品與材質更柔和的光影層次與細膩質感。<br />

                <strong>運動 × 設計，形塑羽毛球的無限可能</strong><br />
                ACE PLAY SPORT 不僅是一間運動品牌門市，透過當代設計打造一處專業且富有文化內涵的運動空間。不僅單純是選購裝備，更是感受羽毛球運動精神與美學的沉浸式體驗場域，為傳統運動空間開啟全新敘事可能。
            </>
        ),
    },
    gallery: [
        require("assets/images/portfolio/ace-play-sport/01.jpg"),
        require("assets/images/portfolio/ace-play-sport/02.jpg"),
        require("assets/images/portfolio/ace-play-sport/03.jpg"),
        require("assets/images/portfolio/ace-play-sport/04.jpg"),
        require("assets/images/portfolio/ace-play-sport/05.jpg"),
        require("assets/images/portfolio/ace-play-sport/06.jpg"),
        require("assets/images/portfolio/ace-play-sport/07.jpg"),
        require("assets/images/portfolio/ace-play-sport/08.jpg"),
        require("assets/images/portfolio/ace-play-sport/09.jpg"),
        require("assets/images/portfolio/ace-play-sport/10.jpg"),
        require("assets/images/portfolio/ace-play-sport/11.jpg"),
        require("assets/images/portfolio/ace-play-sport/12.jpg"),
        require("assets/images/portfolio/ace-play-sport/13.jpg"),
        require("assets/images/portfolio/ace-play-sport/14.jpg"),
        require("assets/images/portfolio/ace-play-sport/15.jpg"),
        require("assets/images/portfolio/ace-play-sport/16.jpg"),
        require("assets/images/portfolio/ace-play-sport/17.jpg"),
        require("assets/images/portfolio/ace-play-sport/18.jpg"),
        require("assets/images/portfolio/ace-play-sport/19.jpg"),
        require("assets/images/portfolio/ace-play-sport/20.jpg"),
        require("assets/images/portfolio/ace-play-sport/21.jpg"),
        require("assets/images/portfolio/ace-play-sport/22.jpg"),
        require("assets/images/portfolio/ace-play-sport/23.jpg"),
        require("assets/images/portfolio/ace-play-sport/24.jpg"),
        require("assets/images/portfolio/ace-play-sport/25.jpg"),
        require("assets/images/portfolio/ace-play-sport/26.jpg"),
        require("assets/images/portfolio/ace-play-sport/27.jpg"),
        require("assets/images/portfolio/ace-play-sport/28.jpg"),
        require("assets/images/portfolio/ace-play-sport/29.jpg"),
        require("assets/images/portfolio/ace-play-sport/30.jpg"),
    ],
};

export default acePlaySport;
