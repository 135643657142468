import acePlaySport from './acePlaySport';
import chitaoflower from './chitaoflower';
import pandaTeaCFPoloPark from './pandaTeaCFPoloPark';
import pandaTea885 from './pandaTea885';
import f100 from './f100';
import songyeTech from './songyeTech';
import yinShanRuShi from './yinShanRuShi';
import songGuang from './songGuang';
import riri from "./riri";
import yangyang from "./yangyang";
import smallResidence from "./smallResidence";
import hanasho from "./hanasho";
import yubai from "./yubai";
import plantea from "./plantea";
import highTopMedical from "./highTopMedical";
import sunset from "./sunset";
import upDownResidence from "./upDownResidence";
import shixu from "./shixu";

const portfolios = [
  acePlaySport,
  chitaoflower,
  pandaTeaCFPoloPark,
  pandaTea885,
  f100,
  songyeTech,
  yinShanRuShi,
  songGuang,
  riri,
  yangyang,
  smallResidence,
  hanasho,
  yubai,
  plantea,
  highTopMedical,
  sunset,
  upDownResidence,
  shixu
];

export default portfolios;
